import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
;
export var Card;
(function (Card) {
    Card.Vertical = ({ image, id, classes, title, content, htmlContent, nodeContent, tags, }) => {
        return (_jsxs("div", { id: id, className: `max-w-sm rounded overflow-hidden shadow-lg ${classes?.container}`, children: [image && _jsx("img", { className: `w-full ${classes?.image}`, src: image.src, alt: image.alt }), _jsxs("div", { className: "px-6 py-4", children: [title && _jsx("div", { className: `font-bold text-xl mb-2 ${classes?.title}`, children: title }), content && _jsx("p", { className: "text-gray-700 text-base", children: content }), htmlContent && _jsx("div", { dangerouslySetInnerHTML: { __html: htmlContent }, className: classes?.htmlContent }), nodeContent && _jsx("div", { className: `${classes?.nodeContent}`, children: nodeContent })] }), tags && _jsx("div", { className: `px-6 pt-4 pb-2 ${classes?.tags}`, children: tags.map((tag) => (_jsx("span", { className: `inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 ${classes?.tag}`, children: tag.content }))) })] }));
    };
    Card.Horizontal = ({ image, id, classes, title, subTitle, miniCard, description }) => {
        return (_jsxs("div", { id: id, className: `max-w-sm w-full lg:max-w-full lg:flex ${classes?.container}`, children: [image && _jsx("div", { style: {
                        backgroundImage: `url(${image.src}`
                    }, className: `h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden ${classes?.image}`, title: "Woman holding a mug" }), _jsxs("div", { className: "border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal", children: [_jsxs("div", { className: "mb-8", children: [subTitle && _jsx("p", { className: `text-sm text-gray-600 flex items-center ${subTitle.className}`, children: subTitle.content }), title && _jsx("div", { className: `text-gray-900 font-bold text-xl mb-2 ${classes?.title}`, children: title }), description && _jsx("p", { className: `text-gray-700 text-base ${classes?.htmlContent}`, children: description })] }), miniCard && _jsxs("div", { className: `flex items-center ${miniCard.className}`, children: [_jsx("img", { className: `w-10 h-10 rounded-full mr-4
            ${miniCard?.imageClass}`, src: miniCard.src, alt: miniCard.alt }), _jsx("div", { className: `text-sm ${miniCard.contentClass}`, children: miniCard.content })] })] })] }));
    };
})(Card || (Card = {}));
