import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { useReducerWithLogger } from "../hooks";
export const makeContext = (reducer, initialData) => {
    const DataContext = createContext(null);
    const DataDispatchContext = createContext(null);
    const ContextProvider = ({ children }) => {
        const [data, dispatch] = useReducerWithLogger(reducer, initialData);
        return (_jsx(DataContext.Provider, { value: data, children: _jsx(DataDispatchContext.Provider, { value: dispatch, children: children }) }));
    };
    const useDataContext = () => useContext(DataContext);
    const useDataDispatchContext = () => useContext(DataDispatchContext);
    return {
        Provider: ContextProvider,
        useDataContext,
        useDataDispatchContext
    };
};
