import { jsx as _jsx } from "react/jsx-runtime";
import { useDragableElement } from '@ttt-utils/react-hooks';
import { useEffect, useState } from 'react';
import "./glowingBall.style.css";
export const GlowingBall = ({ className, id, delay = 0, style }) => {
    const [isDisplaying, setIsDisplaying] = useState(delay === 0);
    useEffect(() => {
        if (delay && delay > 0) {
            setTimeout(() => {
                setIsDisplaying(true);
            }, delay);
        }
    }, [delay]);
    const { onSelect } = useDragableElement(id);
    return (_jsx("div", { className: `absolute duration-0 ${isDisplaying ? className : "hidden"}`, onClick: onSelect, id: id, children: _jsx("div", { className: 'relative', children: _jsx("div", { style: style || {}, className: `absolute cursor-grab z-50 top-[calc(50%-20px)] left-[50%-20px] w-16 h-16 rounded-full bg-white glowing-ball` }) }) }));
};
