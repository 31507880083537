import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {},
});
export const addResource = (key, value, namespace) => i18n.addResourceBundle(key, namespace || "default", value);
export const getResourceBundle = (key, namespace) => i18n.getResourceBundle(key, namespace || "default");
export default i18n;
