import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var ListView;
(function (ListView) {
    ListView.Ordered = ({ classes, items = [], title, disc, inside, unstyled, children }) => {
        return _jsxs("div", { className: classes?.container, children: [title &&
                    _jsx("h2", { className: `mb-2 text-lg font-semibold text-gray-900 dark:text-white ${classes?.title}`, children: title }), _jsxs("ul", { className: `max-w-md space-y-1 text-gray-500 dark:text-gray-400 ${classes?.ul} ${disc && 'list-disc'} ${inside && "list-inside"} ${unstyled && "list-none"}`, children: [items.map((item) => (_jsx("li", { className: item.className, children: item.content }, `list-item-${item.id}`))), children && _jsx("li", { className: classes?.li, children: children })] })] });
    };
    ListView.Unordered = ({ classes, items = [], title, decimal, inside, unstyled, children }) => {
        return _jsxs("div", { className: classes?.container, children: [title &&
                    _jsx("h2", { className: `mb-2 text-lg font-semibold text-gray-900 dark:text-white ${classes?.title}`, children: title }), _jsxs("ol", { className: `ps-5 mt-2 space-y-1 ${classes?.ul} ${decimal && 'list-decimal'} ${inside && "list-inside"} ${unstyled && "list-none"}`, children: [items.map((item) => (_jsx("li", { className: item.className, children: item.content }, `list-item-${item.id}`))), children && _jsx("li", { className: classes?.li, children: children })] })] });
    };
})(ListView || (ListView = {}));
