import { jsx as _jsx } from "react/jsx-runtime";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ReactApp } from "../dom";
export const buildAppRoute = (router) => createBrowserRouter(router);
export const AppRouter = ({ router }) => {
    const _router = buildAppRoute(router);
    return _jsx(RouterProvider, { router: _router });
};
export const AppWithRoute = ({ rootId, providers, strictMode, router, }) => {
    ReactApp({
        strictMode: strictMode,
        providers: providers,
        rootId: rootId,
        renderedApp: _jsx(AppRouter, { router: router }),
    });
};
