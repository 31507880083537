import { makeContext } from "@ttt-core/react";
import i18n from "../i18n";
export var LanguageKey;
(function (LanguageKey) {
    LanguageKey["vi"] = "vi";
    LanguageKey["en"] = "en";
})(LanguageKey || (LanguageKey = {}));
export var MultiLanguageActionType;
(function (MultiLanguageActionType) {
    MultiLanguageActionType["updateLanguage"] = "UPDATE_LANGUAGE";
    MultiLanguageActionType["initState"] = "INIT_STATE";
})(MultiLanguageActionType || (MultiLanguageActionType = {}));
export var LanguageCode;
(function (LanguageCode) {
    LanguageCode["vi"] = "vi-VN";
    LanguageCode["en"] = "en-EN";
})(LanguageCode || (LanguageCode = {}));
export const getLanguageCode = (key) => {
    return LanguageCode[key];
};
const reducer = (state, action) => {
    switch (action.type) {
        case MultiLanguageActionType.updateLanguage:
            i18n.changeLanguage(action.payload.language);
            return {
                ...state,
                language: action.payload.language,
                languageCode: getLanguageCode(action.payload.language),
            };
        case MultiLanguageActionType.initState:
            return action.payload;
        default:
            return state;
    }
};
// Create the counter context using makeContext
export const MultilanguageContext = makeContext(reducer, {
    loading: false,
    language: LanguageKey.en,
    languageCode: LanguageCode.en
});
