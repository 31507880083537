import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCustomCursor } from "@ttt-utils/react-hooks";
import "./customShadow.style.css";
export const CursorShadow = ({ className, children }) => {
    const customMouseMove = (e) => {
        const shadow = document.querySelector('#custom-cursor-shadow .shadow');
        if (!shadow)
            return;
        let x = e.clientX - (document.documentElement.clientWidth * 1.5);
        let y = e.clientY - (document.documentElement.clientHeight * 1.5);
        shadow.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
    };
    useCustomCursor('custom-cursor-shadow', { customMouseMove });
    return (_jsxs("div", { id: "custom-cursor-shadow", className: `wrapper ${className}`, children: [_jsx("div", { className: "shadow" }), children && children] }));
};
