import { GlowingJump } from "./GlowingJump";
import { GlowingBall } from "./GlowingBall";
import { GlowingLine } from "./GlowingLight";
import { DrawingBorderButton as DrawingBorderButtonComponent } from "./DrawingBorderButton";
import { RippleButton as RippleButtonComponent } from "./RippleButton";
import { CursorCustom as CursorCustomComponent } from "./CursorCustom";
import { CursorShadow as CursorShadowComponent } from "./CursorShadow";
import { BlackHole as BlackHoleComponent } from "./BlackHole";
import { SpaceIn as SpaceInComponent } from "./SpaceIn";
import { WaterText as WaterTextComponent } from "./WaterText";
import { BubbleWrapper as BubbleWrapperComponent } from "./BubbleWrapper";
import { PlasmaBall as PlasmaBallComponent } from "./PlasmaBall";
import { TypeWriter as TypeWriterComponent } from "./TypeWriter";
import { LoadingBlock as LoadingBlockComponent } from "./LoadingBlock";
import "./tailwind.css";
export var Animates;
(function (Animates) {
    Animates.GlowingJumpAnim = GlowingJump;
    Animates.GlowingBallAnim = GlowingBall;
    Animates.GlowingLineAnim = GlowingLine;
    Animates.DrawingBorderButton = DrawingBorderButtonComponent;
    Animates.RippleButton = RippleButtonComponent;
    Animates.CursorCustom = CursorCustomComponent;
    Animates.CursorShadow = CursorShadowComponent;
    Animates.BlackHole = BlackHoleComponent;
    Animates.SpaceIn = SpaceInComponent;
    Animates.WaterText = WaterTextComponent;
    Animates.BubbleWrapper = BubbleWrapperComponent;
    Animates.PlasmaBall = PlasmaBallComponent;
    Animates.TypeWriter = TypeWriterComponent;
    Animates.LoadingBlock = LoadingBlockComponent;
})(Animates || (Animates = {}));
