import { useRef, useImperativeHandle } from "react";
export const useAnimationWrapperRef = (ref) => {
    const wrapperRef = useRef(null);
    useImperativeHandle(ref, () => ({
        triggerQuitAnimate: (quitAnimate) => {
            if (wrapperRef.current) {
                wrapperRef.current.classList.remove('animate-slide_up');
                wrapperRef.current.classList.add(quitAnimate);
            }
        },
    }));
    return wrapperRef;
};
